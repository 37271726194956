/* Agregar fuente Poppi */
/* 
@font-face {
    font-family: 'poppi-extra-bold';
    src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf');
}

@font-face {
    font-family: 'poppi-black';
    src: url('../assets/fonts/Poppins/Poppins-Black.ttf');
}

@font-face {
    font-family: 'poppi-bold';
    src: url('../assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'SpaceGrotesk-Medium';
    src: url('../assets/fonts/Poppins/Poppins-Medium.ttf');
} */

@font-face {
    font-family: 'Space_Grotesk-light';
    src: url('../assets/fonts/Poppins/Poppins-Light.ttf');
}

/* Fuentes Mulish */
/* @font-face {
    font-family: 'mulish';
    src: url('../assets/fonts/Mulish/Mulish-VariableFont_wght.ttf');
} */

@font-face {
    font-family: 'Space_Grotesk';
    src: url('../assets/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Space_Grotesk';
    src: url('../assets/fonts/Space_Grotesk/SpaceGrotesk-Medium.ttf');
}

@font-face {
    font-family: 'Space_Grotesk-light';
    src: url('../assets/fonts/Space_Grotesk/SpaceGrotesk-Light.ttf');
}

@font-face {
    font-family: 'Space_Grotesk';
    src: url('../assets/fonts/Space_Grotesk/SpaceGrotesk-SemiBold.ttf');
}



/* Clases para las fuentes Poppi */
.poppi-bold {
    font-family: 'poppi-bold', sans-serif;
}

.Space_Grotesk-light {
    font-family: 'Space_Grotesk-light', sans-serif;
}

/* Clases para la fuente mulish */
.mulish-font-navbar {
    font-family: 'mulish', sans-serif;
    color: #6767DB;
    font-size: 30px;
    font-weight: 800;
    line-height: 32px;
    word-wrap: break-word;
}

/* Clases para la cabecera */
.bg-white {
    background-color: #ffffff;
}

.menu-item {
    color: #344054;
    font-weight: 500;
    line-height: 24px;
}

.btn-pink {
    background-color: #E7E9F0;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    color: #101828;
}

.btn-green {
    background-color: #6767DB;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    color: #1D2939;
}

.btn-green:hover {
    background-color: #6767DB;
}

/* Clases para la primera seccion */
#main-text-first-section{
    font-size: 4.5rem;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    font-weight: 600;
    word-wrap: break-word
}

.second-paragraph-first-section {
    font-size: 1.3rem;
    color: #1A1E2C;
    font-family: 'SpaceGrotesk-Medium',sans-serif;
    font-weight: 400;
}

.green-text {
    color: #6767DB;
}

/* Clases para la segunda seccion */
.bg-section2 {
    background-color: #F2F5FE;
}

.description-section2 {
    font-size: 24px;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    font-weight: 600;
}

/* Clases para la tercera sección */
.imgage-icon-third-section {
    min-width: 56px;
    min-height: 56px;
    width: 56px;
    height: 56;
}

.title-third-section {
    color: #1A1E2C;
    font-size: 2rem;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    font-weight: 600;
}

.subtitle-third-section {
    color: #1A1E2C;
    font-size: 1rem;
    font-family: 'Space_Grotesk-light', sans-serif;
    font-weight: 400;
}

.title-card-third-section {
    color: #101828;
    font-weight: 600;
    font-size: 1.4rem;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
}

.description-card-third-section {
    font-size: 1rem;
    color: #101828;
    font-family: 'Space_Grotesk-light', sans-serif;
    font-weight: 500;
}

/* Clases para la sección 4 */
.container-section4 {
    gap: 86px;
    padding: 56px;
    background-color: #6767DB;
    border-radius: 10px;
}

.container-section4 h1 {
    color: #101828;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 56px;
    word-wrap: break-word
}

.btn-dark {
    background-color: #101828;
    color: #FFFFFF;
}

/* Reglas para el footer */

.mb-20 {
    margin-bottom: 15rem!important;
}

.mb-21 {
    margin-bottom: 1rem!important;
}

.container-footer {
    background-color: #13005A;
    padding-top: 64px;
    padding-bottom: 64px; 
}

.container-green-footer {
    margin-top: -15vh;
    position: relative;
}

.title-footer {
    color: #6767DB;
    font-family: 'SpaceGrotesk-Medium', sans-serif;
    font-size: 2rem;
    font-weight: 800px;

}

.container-footer .text-white {
    color: white;
    font-family: 'Space_Grotesk-light', sans-serif;
    font-size: 16px;
}

.main-menu-footer a {
    color: white;
    font-family: 'Space_Grotesk-light', sans-serif;
}

.line-divider {
    color: rgba(152, 162, 179, 0.20);
}

.text-copyright {
    color: #98A2B3;
    font-family: 'Space_Grotesk-light', sans-serif;
    font-weight: 400;
}

/* Media queries */
@media (max-width: 1440px) {
    #main-text-first-section{
        font-size: 44px;
        font-weight: 600;
        line-height: 56px;
    }

    .container-green-footer {
        margin-top: -35vh;
        position: relative;
    }
}